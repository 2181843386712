//@ts-ignore
import { Ziggy } from '@/js/ziggy'
import Admin from '@/models/Admin'
import { MoneyData } from '@/models/Money'
import Theme from '@/theme/js/main/app'
import Gate from '@/ts/gate'
import Form from '@/vue/components/Form.vue'
import Tooltip from '@/vue/components/Tooltip'
import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { createInertiaApp, router } from '@inertiajs/vue3'
import axios, { AxiosError } from 'axios'
import 'bootstrap-notify'
import $ from 'jquery'
import Cookie from 'js-cookie'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import moment from 'moment'
import SweetAlert from 'sweetalert2'
import { createApp, h } from 'vue'
import { ZiggyVue } from 'ziggy-js'

createInertiaApp({
	progress: {
		color: '#CCCCCC',
		includeCSS: true,
	},
	title: (title) => `${title} | Stacs`,
	//@ts-ignore
	resolve: (name) => resolvePageComponent(`../pages/${name}`, import.meta.glob('../pages/**/*.vue', { eager: true })),
	//@ts-ignore
	setup({ el, App, props, plugin }) {
		const app = createApp({ render: () => h(App, props) })
		//@ts-ignore
		const user = props.initialPage.props?.auth?.admin as Admin
		app.use(plugin)
		app.use(ZiggyVue, Ziggy)
		if (['production', 'development', 'staging'].includes(import.meta.env.VITE_APP_ENV)) {
			Bugsnag.start({
				apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
				plugins: [new BugsnagPluginVue()],
				enabledReleaseStages: ['production', 'development', 'staging'],
				releaseStage: import.meta.env.VITE_APP_ENV,
				user: {
					id: user?.id.toString(),
					name: user?.fullname,
					email: user?.email,
					//role: user?.role,
				},
			})
			BugsnagPerformance.start({
				apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
				releaseStage: import.meta.env.VITE_APP_ENV,
			})
			//@ts-ignore
			app.use(Bugsnag?.getPlugin('vue'))
		}

		app.config.globalProperties.$http = axios
		//@ts-ignore
		app.config.globalProperties.$gate = new Gate(user)
		//@ts-ignore
		app.config.globalProperties.oneui = new Theme()
		app.config.globalProperties.$user = user
		app.config.globalProperties.$render = Renderer
		app.config.globalProperties.$router = router
		app.config.globalProperties.$sweet = SweetAlert

		app.directive('tooltip', Tooltip)
		if (import.meta.env.VITE_APP_DEBUG) {
			app.config.performance = true
		}
		if (import.meta.env.VITE_APP_ENV === 'local') {
			const mountPoint = document.querySelector('#app')
			// @ts-ignore
			if (mountPoint && mountPoint?.__vue_app__ !== undefined) {
				// The Vue app is mounted
				console.log('App is mounted, unmounting')
				// @ts-ignore
				mountPoint.__vue_app__?.unmount()
			}
		}
		return app.mount(el)
	},
})

axios.defaults.baseURL = import.meta.env.VITE_APP_URL
if (import.meta.env.VITE_APP_DEBUG) {
	Cookie.set('XDEBUG_SESSION', 'start')
	axios.defaults.headers.common['XDEBUG_SESSION'] = 'vsc'
}

// axios.interceptors.response.use(
// 	(response) => response.data,
// 	(error) => {
// 		if (error.response && 419 === error.response.status) {
// 			window.location.reload()
// 		}

// 		return Promise.reject(error)
// 	},
// )
//axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ?? ''

$(() => {
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
			XDEBUG_SESSION: 'vsc',
		},
	})

	$('body').on('click', 'inertia-link', (e) => {
		e.preventDefault()
		console.log('clicked')
		router.visit((e.currentTarget as HTMLAnchorElement).href)
	})
})

export const error = (e: AxiosError | Error, view?: ViewModel, toast?: boolean) => {
	console.error(e)
	if (typeof view?.message !== 'undefined') {
		//@ts-ignore
		view.message = e.response?.data?.message
	}

	if (typeof view?.form !== 'undefined') {
		//@ts-ignore
		view.form?.setErrors(e.response.data?.errors)
	}

	if (toast) {
		$.notify(
			{
				icon: 'fas fa-exclamation-triangle',
				//@ts-ignore
				message: e.response?.data?.message,
			},
			{
				type: 'danger',
				placement: {
					from: 'top',
					align: 'center',
				},
			},
		)
	}
}

interface ViewModel {
	message: string
	form: InstanceType<typeof Form>
}

export const Renderer = {
	date: (value?: string): string => (moment(value).isValid() ? moment(value).format('MMMM Do, YYYY') : 'N/A'),
	bool: (value?: boolean): string => (value ? 'Yes' : 'No'),
	enum: (value: string): string => label(value),
	money: (value: MoneyData | number, currency: string = 'NGN'): string => {
		if (typeof value === 'number') {
			return (value / 100)?.toLocaleString('en-NG', { style: 'currency', currency })
		} else if (typeof value === 'object') {
			return value.formatted
		} else {
			return '<i>N/A</i>'
		}
	},
}

const label = (value: string) =>
	value
		.split('_')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
